/* Raleway font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Great Vibes font */
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

/* Tailwind stuffs */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base font */
@layer base {
  html {
    font-family: 'Raleway', sans-serif;
  }
}
.portoImg {
  width:290px;
  height: 170px;
}

.card {
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.1);
}

.topindex {
  z-index: 999;
}